import React from 'react'

import InvestmentsTab from 'src/components/InvestmentsTab'

const OutrosInvestimentos = () => {
  return (
    <section id='outros-investimentos'>
      <div className='container pt-5 py-md-5'>
        <h2 className='fs-28 fs-md-42 text-md-center mt-2'>Veja outros tipos de investimentos</h2>
        <InvestmentsTab
          section='dobra_2'
          elementAction='click button'
          section_name='Veja outros tipos de investimentos'
        />
      </div>
    </section>
  )
}

export default OutrosInvestimentos
