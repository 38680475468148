import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import {
  Hero,
  OutrosInvestimentos,
  NovidadesInvestimentos,
  FaqSection,
} from './sections/_index'

function Debentures () {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <OutrosInvestimentos />
      <NovidadesInvestimentos />
      <FaqSection />
    </Layout>
  )
}

export default Debentures
