import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerDesktop: imageSharp(fluid: {originalName: { regex: "/banner-renda-fixa-desktop/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerMobile: imageSharp(fluid: {originalName: { regex: "/banner-renda-fixa-mobile/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerHeroDebentures: imageSharp(fluid: {originalName: { regex: "/banner-lp-debentures/" }}) {
        fluid(maxWidth: 382, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
