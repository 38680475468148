import React from 'react'

import FAQ from 'src/components/StructuredData/FAQ'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'

import pageContext from '../../pageContext.json'
import * as S from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const FaqSection = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }
  return (
    <S.FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-4 pb-md-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-3'>
            <h2 className='fs-28 fs-md-42 text-md-center'>Perguntas frequentes</h2>
            <p className='fs-16 text-md-center'>
              <span className='d-lg-block'>Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas</span> perguntas ou entre em contato com a gente.
            </p>
          </div>
          <FAQ search={false} id='investimentos-debentures' styles='summary-content px-0' data={pageContext.structuredData.faq} />
        </div>
      </div>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_05'
      />
    </S.FAQSection>
  )
}

export default FaqSection
