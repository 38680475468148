import React from 'react'

import Link from 'src/components/GatsbyLinkWrapper'
import BlogArticles from 'src/components/BlogArticles'

const NovidadesInvestimentos = () => {
  return (
    <section id='novidades-investimentos' className='py-md-4'>
      <div className='container'>
        <div className='col-12 mb-4'>
          <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center'>Os melhores conteúdos sobre investimentos</h2>
          <p className='text-left mt-1 text-lg-center mb-0'>
            Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
          </p>
        </div>
        <BlogArticles
          type='investments'
          section='dobra_3'
          elementAction='click button'
          elementName='Saiba mais'
          sectionName='Confira algumas dicas para investir melhor'
        />
      </div>
    </section>
  )
}

export default NovidadesInvestimentos
