import styled from 'styled-components'

export const Section = styled.section`
  .btn {
    min-width: 100%;
    height: 48px;
  }

  .gatsby-image-wrapper {
    margin: auto;
    max-width: 382px;
  }
`
